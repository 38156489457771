import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../src/components/modules/Layout"
import ProductHeader from "../../../src/components/blocks/ProductHeader"
import ProductFinderBlock from "../../../src/components/blocks/ProductFinderBlock"
import BodyBlocks from "../../../src/components/technical/BodyBlocks"

const ProductGroupTemplate = ({ data }) => {
  const entry = data.entry
  const area = data.area
  const variants = data.variants.edges
  let products = data.products.edges

  let sb_content = false
  if (data?.sb_data?.edges?.length > 0) {
    sb_content = JSON.parse(data.sb_data.edges[0].node.content)
  }

  products.forEach((product) => {
    const allVariants = variants.filter(
      (v) =>
        v.node.parentId === product.node.ID &&
        v.node.locale === product.node.locale &&
        v.node.variantType === product.node.productType
    )
    const linkedVariant = allVariants[Math.floor((allVariants.length - 1) / 2)]
    if (linkedVariant) {
      product.node.variantFullSlug = linkedVariant.node.fullSlug
    }
  })

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    { link: area.fullSlug, link_text: area.name },
  ]

  return (
    <Layout language={entry.language}>
      <ProductHeader
        block={{
          headline: entry?.name,
        }}
        breadcrumbs={breadcrumbs}
      />
      <BodyBlocks body={sb_content?.body_top} />
      <ProductFinderBlock
        sb_metadata={sb_content?.metadata}
        entry={entry}
        products={products}
        scope="group"
        area={area}
      />
      <BodyBlocks body={sb_content?.body_bottom} />
    </Layout>
  )
}

export const query = graphql`
  query GroupQuery(
    $id: String
    $locale: String
    $areaId: String
    $groupSbContentId: String
    $groupSbContentLocaleRegEx: String
  ) {
    entry: contentServProductGroup(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      language
      ID
      fullSlug
      rootPage {
        name
        fullSlug
      }
    }
    products: allContentServProduct(
      filter: { groupId: { eq: $id }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          groupId
          ID
          fullSlug
          externalKey
          locale
          productType
          properties {
            isNewFlag
            isNewStart
            isNewEnd
            mainImage
            shortDescription {
              value {
                Formatted
              }
            }
            productFinder {
              application
              filters
            }
          }
        }
      }
    }
    variants: allContentServVariant(
      filter: { groupId: { eq: $id }, locale: { eq: $locale } }
    ) {
      edges {
        node {
          ID
          locale
          fullSlug
          parentId
          externalKey
          variantType
          groupId
          areaId
        }
      }
    }
    area: contentServProductArea(
      areaId: { eq: $areaId }
      locale: { eq: $locale }
    ) {
      name
      fullSlug
    }
    sb_data: allStoryblokEntry(
      filter: {
        tag_list: { eq: $groupSbContentId }
        field_component: { eq: "pim_editorial_contents_page" }
        full_slug: { regex: $groupSbContentLocaleRegEx }
      }
    ) {
      edges {
        node {
          name
          full_slug
          content
        }
      }
    }
  }
`

export default ProductGroupTemplate
